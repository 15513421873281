<template>
  <v-dialog
    v-model="updateTeamModal"
    width="800px"
    :retain-focus="false"
  >
    <v-card
      class="py-5 pb-5"
    >
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Editar Time
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          class="mx-5 mt-5"
          lazy-validation
          @submit.prevent="updateTeam()"
        >
          <v-text-field
            v-model="team.name"
            :rules="[rules.required]"
            label="Nome do time *"
            outlined
            required
          />

          <image-uploader
            title="Clique aqui para enviar o escudo do Time"
            subtitle="Tamanho recomendado: 150 por 150 pixels"
            :saving="saving"
            :savedImage="olderImage"
            :errorImage="imageOffIcon"
            :fullWidth="true"
            previewWidth="150px"
            previewHeight="150px"
            @img-uploaded="handleImage"
            @img-removed="handleRemoveImage"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="updateTeam()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    ImageUploader: () => import('../../../components/ImageUploader.vue')
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      saving: false,
      valid: true,
      team: {
        name: '',
        imageType: undefined
      },
      olderImage: '',
      imageOffIcon,
      image: null,
      rules: {
        required: value => !!value || 'Este campo é obrigatório.'
      }
    }
  },
  computed: {
    ...mapState(['teams']),
    updateTeamModal: {
      get () {
        return this.$store.state.updateTeamModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateTeamModal',
          value: val
        })
      }
    }
  },
  watch: {
    updateTeamModal (val) {
      const team = this.teams.data.filter((item) => item._id === this.id)[0]
      this.team.name = team.name
      this.olderImage = team.image
    }
  },
  methods: {
    updateTeam () {
      if (this.$refs.form.validate()) {
        this.saving = true
        this.$http.put(`/teams/${this.id}`, this.team)
          .then((res) => {
            const { data } = res
            if (data.putUrl) {
              this.uploadImage(data.putUrl, this.image, this.success, this.error)
            } else {
              this.success()
            }
          })
          .catch((err) => {
            this.saving = false
            this.$toast.error(err.response.data.message)
          })
      }
    },
    success () {
      this.saving = false
      this.updateTeamModal = false
      this.$emit('updated')
      this.$toast.success('Cadastro atualizado com sucesso.')
    },
    error () {
      this.saving = false
      this.updateTeamModal = false
      this.$toast.error('Algo de errado aconteceu ao salvar a imagem.')
    },
    handleImage (file) {
      this.team.imageType = file.type
      this.image = file
    },
    handleRemoveImage () {
      this.team.imageType = undefined
      this.olderImage = null
      this.image = null
    }
  }
}
</script>
